export const filterStaffByName = (item, queryText) => {
    let firstName = ''
    let lastName = ''
    let haystack = ''

    if (item.first_name) {
        firstName = item.first_name.toLocaleLowerCase()
    }
    if (item.last_name) {
        lastName = item.last_name.toLocaleLowerCase()
    }
    haystack = firstName + ' ' + lastName;

    return haystack.includes(queryText.toLocaleLowerCase())
}

export const getStaffFullName = (data) => {
    return data.item.first_name + ' ' + data.item.last_name
}