<template>
    <v-container>
        <v-row>
            <v-col :key="componentKey" cols="12">
                <kurcc-autocomplete
                    :display-function="getStaffFullName"
                    :filter="filterStaffByName"
                    :items="users"
                    :loading="isLoading"
                    :multiple="false"
                    :name="$vuetify.lang.t('$vuetify.pages.groups.member')"
                    :selected-items.sync="selectedMember"/>
                <v-checkbox v-model="isAdmin" :label="$vuetify.lang.t('$vuetify.pages.groups.groupAdmin')"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {filterStaffByName, getStaffFullName} from "@/modules/staff/helpers/kurccAutocompleteHelpers";
import {SET_GROUP_MEMBER_TO_ADD} from "@/modules/app/store/mutation-types";

export default {
    name: "KurccGroupMemberDialog",
    components: {
        KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
    },
    props: ['item'],
    data() {
        return {
            model: false,
            users: [],
            isAdmin: false,
            filterStaffByName,
            getStaffFullName,
            isLoading: false,
            opened: false,
            componentKey: new Date().getTime()
        }
    },
    computed: {
        selectedMember: {
            get() {
                return this.$store.state.groups.groupMemberManipulate
            },
            set(v) {
                if (v) v.is_admin = this.isAdmin
                this.$store.commit(SET_GROUP_MEMBER_TO_ADD, v)
            }
        }
    },
    watch: {
        item: {
            handler: function () {
                this.componentKey++
                if (!this.opened) {
                    this.isAdmin = false
                    this.remove()
                    this.getAllStaff()
                    this.opened = true
                } else this.opened = false
            },
            immediate: true
        },
        isAdmin: function (val) {
            if (this.selectedMember) this.selectedMember.is_admin = val
        }
    },
    methods: {
        remove() {
            this.selectedMember = null
        },
        getAllStaff() {
            this.isLoading = true
            this.$store.dispatch('getAllStaff', {
                withIncludes: false,
                additionalParams: [`exclude_group_id=${this.$route.params.group_id}`]
            }).then((res) => {
                    this.users = res.items
                }
            ).finally(() => (this.isLoading = false))
        }
    }
}
</script>
